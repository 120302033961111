<div class="no-print">
	<h1>sale signs</h1>

	<select [(ngModel)]="template.name">
		<option *ngFor="let name of template.names" [value]="name.name" >
			{{name.text}}
		</option>
	</select>

	<input *ngIf="template.name !== 'app-fallsale'" placeholder="Enter Plant SKU" (keyup.enter)="getPlant(sku.value)" #sku type="text" value="" #sku autofocus [(ngModel)]="insku">
	<div *ngIf="notFound">
		Plant wasn't found. 
	</div>
</div>

<div class="trunksigns">
	<app-trunksign [plant]="plant" *ngIf="template.name=='app-trunksign'&&plant"></app-trunksign>
	<app-trunksign [plant]="plant" *ngIf="template.name=='app-trunksign'&&plant"></app-trunksign>
</div>


<app-minisalesign [plant]="plant" *ngIf="template.name=='app-minisalesign'&&plant"></app-minisalesign>
<app-minisalesign [plant]="plant" *ngIf="template.name=='app-minisalesign'&&plant"></app-minisalesign>
<app-minisalesign [plant]="plant" *ngIf="template.name=='app-minisalesign'&&plant"></app-minisalesign>
<app-minisalesign [plant]="plant" *ngIf="template.name=='app-minisalesign'&&plant"></app-minisalesign>

<app-locationstrip *ngIf="template.name=='app-locationstrip'"></app-locationstrip>

<app-saletier [plant]="plant" *ngIf="template.name=='app-saletier'"></app-saletier>

<app-fallsale *ngIf="template.name=='app-fallsale'"></app-fallsale>

<div class="portrait-print" *ngIf="template.name=='app-utilitysign'">
	<app-utilitysign [plant]="plant" *ngIf="template.name=='app-utilitysign'&&plant"></app-utilitysign>
	<app-utilitysign [plant]="plant" *ngIf="template.name=='app-utilitysign'&&plant"></app-utilitysign>
	<app-utilitysign [plant]="plant" *ngIf="template.name=='app-utilitysign'&&plant"></app-utilitysign>
	<app-utilitysign [plant]="plant" *ngIf="template.name=='app-utilitysign'&&plant"></app-utilitysign>
</div>

<app-basicsign *ngIf="template.name=='app-basicsign'"></app-basicsign>