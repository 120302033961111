<label>Will import plant info from thetreefarm.com into api database</label>
<br>
<input [(ngModel)]="sku" (keyup.enter)="lookup(sku)" placeholder="input SKU and press enter">
<button (click)="lookup(sku)">Import Data</button>
<br>
<br>
<label>Will import plant image from thetreefarm.com into api storage</label>
<br>
<input [(ngModel)]="imageSku" (keyup.enter)="lookupImage(imageSku)" placeholder="input SKU and press enter">
<button (click)="lookupImage(imageSku)">Import Images</button>
{{response}}