import { Injectable } from '@angular/core';
import { Http, Headers, URLSearchParams } from '@angular/http';
import { RequestMethod, RequestOptions, RequestOptionsArgs } from '@angular/http';
import { Response } from '@angular/http';
import { Observable } from 'rxjs/Observable';


@Injectable()
export class SalestaxService {

  private basePath = 'https://rest.avatax.com/api/v2/';
  private apiPath = 'https://rest.avatax.com/api/v2/taxrates/byaddress';
  private login = 'pljspahn@gmail.com';
  private pass = 'Avatax089734!';
  private key = 'EB8445D4E21239BA';

  public defaultHeaders: Headers = new Headers();

  // private defaultHeaders: Headers = new Headers();

  constructor(protected http: Http) {
    this.defaultHeaders.append('Authorization', 'Basic ' + btoa(this.login + ':' + this.pass));
  }

  public test(): Observable<Object> {

    let path = this.apiPath + `?line1=11868 Mineral Road&city=Longmont&region=Colorado&postalCode=80504&country=US`;
    let headers = new Headers(this.defaultHeaders.toJSON());

    // headers.set('Authorization', 'Basic MjAwMDEwNDE5NzpGMjdEREEwQTc2OTBDMzU4');
    // headers.set('Authorization', 'Basic ' + btoa(this.login+':'+this.pass));
   
    let options: RequestOptionsArgs = new RequestOptions({
      method: RequestMethod.Get,
      headers: headers
    })

    return this.http.request(path, options);
  }

  public ping(): Observable<Object> {
    let path = 'https://rest.avatax.com/api/v2/utilities/ping';
    let headers = new Headers(this.defaultHeaders.toJSON());

    // headers.set('Authorization', 'Basic MjAwMDEwNDE5NzpGMjdEREEwQTc2OTBDMzU4');

    // headers.set('Authorization', 'Basic ' + btoa(this.login+':'+this.pass));
   
    let options: RequestOptionsArgs = new RequestOptions({
      method: RequestMethod.Get,
      headers: headers,
      withCredentials: true
    })

    return this.http.request(path, options);
  }

  public taxByAddress(address: any): Observable<Object> {
    let path = this.apiPath;
    let params = new URLSearchParams();
    
    params.set('line1', address.line1);
    params.set('city', address.city);
    params.set('region', address.region);
    params.set('postalCode', address.postalCode);
    params.set('country', address.country);

    let options: RequestOptionsArgs = new RequestOptions({
      method: RequestMethod.Get,
      headers: this.defaultHeaders,
      search: params
    })

    return this.http.request(path, options);
  }

  public getSubscriptions(): Observable<Object> {
    let path = this.basePath + `utilities/subscriptions`;
    let options: RequestOptionsArgs = new RequestOptions({
      method: RequestMethod.Get,
      headers: this.defaultHeaders
    })

    return this.http.request(path, options);
  }

  public getJurisdictions(address: any): Observable<Object> {
    let path = this.basePath + `definitions/nexus/byaddress`;
    let params = new URLSearchParams();
    
    params.set('line1', address.line1);
    params.set('city', address.city);
    params.set('region', address.region);
    params.set('postalCode', address.postalCode);
    params.set('country', address.country);
    
    let options: RequestOptionsArgs = new RequestOptions({
      method: RequestMethod.Get,
      headers: this.defaultHeaders,
      search: params
    })

    return this.http.request(path, options);
  }

}
