import { Component, OnInit } from '@angular/core';
import { TflookupService } from '../tflookup.service';
import { DefaultApi } from 'typescript-angular2-client';

@Component({
  selector: 'app-tflookup',
  templateUrl: './tflookup.component.html',
  styleUrls: ['./tflookup.component.scss'],
  providers: [TflookupService]
})
export class TflookupComponent implements OnInit {

  sku: string;
  imageSku: string;

  response: any;

  constructor(private service: TflookupService, private api: DefaultApi) { }

  ngOnInit() {
    
  }

  lookup(sku: string) {
    this.service.importPlant(sku).subscribe(
      response => this.response = JSON.stringify(response),
      error => console.log('error importing SKU: ' + sku + '. Probably used an invalid sku bro', error),
      () => this.afterLookup()
    );
    // this.api.plantMagentoGet(sku).subscribe(
    //   response => this.response = response,
    //   error => console.log('error importing dude', error),
    //   () => this.afterLookup()
    // )

  }

  afterLookup() {
    console.log(this.response);
    return;
  }
  
  lookupImage(sku: string) {
    this.service.importImage(sku).subscribe(
      response => this.response = JSON.stringify(response),
      error => console.log('error with tf image bro', error),
      () => this.afterImage()
    );
  }

  afterImage() {
    return;
  }

}
