import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { DefaultApi } from '../../typescript-angular2-client/api/DefaultApi';
import { Plant } from '../../typescript-angular2-client/model/Plant';
import { Router, ActivatedRoute, Params } from '@angular/router';
import 'rxjs/add/operator/switchMap';
import { TflookupService } from '../tflookup.service';
import { Configuration } from 'typescript-angular2-client';
import { Colors, Dark_Colors } from '../web-colors';
import htmlToImage from 'html-to-image';


@Component({
  selector: 'app-infosign',
  // encapsulation: ViewEncapsulation.None,
  templateUrl: './infosign.component.html',
  styleUrls: ['./infosign.component.scss'],
  providers: [DefaultApi, TflookupService, Configuration]
})

export class InfosignComponent implements OnInit {

	CSS_COLOR_NAMES = Colors;
	DARK_COLORS = Dark_Colors;
	plants: Plant[];
	plant: Plant;
	selectedImage: number;
	plantResponse: any;
	imageResponse: any;
	loaded: boolean = false;
	init: boolean = true;
	defaultWidth: number = 43;
	imageSize: number = this.defaultWidth;
	fontSize: number = 6;
	fontSizeSmall: number = 2;
	imageFound: boolean;
	nameClass: string;
	addedPrice: {
		price: string,
		cents: string,
		text: string,
		enabled: boolean,
		color: string
	};
  	constructor(
			public tf: TflookupService,
			private defaultApi: DefaultApi,
			private route: ActivatedRoute,
			private router: Router) {
					/*
					*/
					window.onbeforeunload = function(e) {
						return 'Did you mean to leave the page?';
					}
			// this.defaultApi.configuration = new Configuration;
			// this.defaultApi.configuration.username = 'tf';
			// this.defaultApi.configuration.password = 'signs';
  	}

	


  	ngAfterViewInit() {
  		
  	}

	ngOnInit() {
		this.getPlant('asp-qua');
		this.addedPrice = {
			enabled: false,
			price: '300',
			cents: '98',
			text: "All Trees This Section\nMix and Match",
			color: 'Red'
		}
		// this.getPlant(this.route.params['plant']);
		// this.route.params
		//	.switchMap((params: Params) => this.defaultApi.plantsSkuGet(params['plant']))
		//	.subscribe((plant: Plant) => this.plant = plant);
	}

	checkLength(e: string) {
		console.log(e);
		if (e.length > 15) {
			this.nameClass = 'long-name';
		} else {
			this.nameClass = 'short-name';
		}
	}

	getPlant(sku: any){
		this.plant = null;
		this.imageSize = this.defaultWidth;
		this.defaultApi.plantsSkuGet(sku).subscribe(
			response => this.plant = response,
			error => console.log('some error', error),
			() => this.afterPlantLoad()
		);
	}

	missingPlant(sku: string) {
		this.tf.importPlant(sku).subscribe(
			response => this.plantResponse = JSON.stringify(response),
			error => console.log('error importing plant', error),
			() => this.afterMissingPlant(sku)
		)
	}
	afterMissingPlant(sku: string) {
		this.getPlant(sku);
		return;
	}

	missingImage(sku: string) {
		this.tf.importImage(sku).subscribe(
			response => this.imageResponse = JSON.stringify(response),
			error => console.log('error importing image I guess bro', error),
			() => this.afterMissingImage(sku)
		)
	}
	afterMissingImage(sku: string) {
		setTimeout(() => {
			this.getPlant(sku);
		}, 2000);
		return;
	}


	setFontSize(operator: any) {
		if (operator === '+') {
			this.fontSize = this.fontSize + .1;
			this.fontSizeSmall = this.fontSizeSmall + .1;
		}
		if (operator === '-') {
			this.fontSize = this.fontSize - .1;
			this.fontSizeSmall = this.fontSizeSmall - .1;
		}
	}

	setImageSize(operator: any) {
		if (operator === '+') {
			this.imageSize = this.imageSize + 1;
		}
		if (operator === '-') {
			this.imageSize = this.imageSize - 1;
		}
		
		// this.imageHeight = this.imageHeight dir 10;
	}

	afterPlantLoad() {
		if (this.plant) {
			this.selectedImage = 0;
			this.checkLength(this.plant.name);
		}
		if(this.init) {
			this.loaded = true;
			this.init = false;
		}
	}

	getPlants(): void {
		this.defaultApi.plantsGet().subscribe(
			response => this.plants = response
		);
	}
	isDark(color) {
		var isdark: boolean;
		if (this.DARK_COLORS.indexOf(color) >= 0) {
		  isdark = true;
		}
	
		return isdark;
	}

	html2jpg() {
		const img = document.getElementById('docpage');
		const p = this.plant;
		htmlToImage.toJpeg(img)
			.then(function (data) {
				let a = document.createElement('a');
				let name:string = p.sku.toLowerCase();
				a.download = name+'.jpg';
				a.href = data;
				document.body.appendChild(a);
				a.click();
				document.body.removeChild(a);
			})
	}
	html2png() {
		const img = document.getElementById('docpage');
		const p = this.plant;
		htmlToImage.toPng(img)
			.then(function (data) {
				let a = document.createElement('a');
				let name:string = p.sku.toLowerCase();
				a.download = name+'.png';
				a.href = data;
				document.body.appendChild(a);
				a.click();
				document.body.removeChild(a);
			})
	}

}
