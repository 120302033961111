<input (keyup.enter)="getPlant(sku)" [(ngModel)]="sku">

<div *ngIf="Plant">

  <select *ngFor="let att of okeys(Plant)">
    <option [value]="att" [label]="att"></option>
  </select>
<!--   
  <select [(ngModel)]="Plant">
    <option *ngFor="let att of okeys(Plant)" [value]="att" [label]="att"></option>
  </select>

  <p>{{Plant}}</p> -->
</div>


<!-- <div *ngIf="Plant">
  <p>{{Plant.name}}</p>
  <ul>
    <li *ngFor="let key of okeys(Plant.attributes)">
      <p>{{key}}</p>
      <ul *ngIf="isArray(Plant.attributes[key])">
        <li *ngFor="let option of Plant.attributes[key]">
          <p>{{option}}</p>
        </li>
      </ul>
      <ul *ngIf="!isArray(Plant.attributes[key])">
        <li>{{Plant.attributes[key]}}</li>
      </ul>
    </li>
  </ul>
  
  <ul *ngIf="Plant.attributes">
    <li>
      <label>
        {{Plant.attributes.deciduousTreeType}}
        <select [(ngModel)]="Plant.attributes.deciduousTreeType">
          <option *ngFor="let type of TreeTypes" [value]="type" >{{type}}</option>
        </select>
        <button (click)="updateField(Plant.sku, 'deciduousTreeType', Plant.attributes.deciduousTreeType)">Update</button>
      </label>
    </li>
  </ul>

</div>
 -->

<!--
  <ul *ngIf="Plant">
    <li *ngFor="let key of okeys(Plant.attributes)">{{key}} - {{Plant.attributes[key]}}</li>
  </ul>
<ul>
  <li *ngFor="let plant of Plants">
    <p>{{plant.name}}</p>
    <input [(ngModel)]="plant.attributes.matureWidth">
    <button (click)="updateField(plant.sku, 'matureWidth', plant.attributes.matureWidth)">
      Update
    </button>
    <p>{{plant.botanicName}}</p>
    <ul>
      <li>{{plant.attributes.lightNeeds}}</li>
    </ul>
  </li>
</ul>
-->