/**
 * Plants API
 * The Tree Farm Plant Information API - All information ©2017 Arbortanics Inc. Unauthorized Access Prohibited
 *
 * OpenAPI spec version: 0.1.0
 * Contact: pljspahn@gmail.com
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

import * as models from './models';

export class PlantAttributesModel {
    attributes(): any[]  {

    return [
        { title: 'Growth Rate', options: [
            { name: 'Fast', value: 'fast'},
            { name: 'Moderate', value: 'moderate'},
            { name: 'Slow', value: 'slow'}
        ]},
        { title: 'Fall Color', options: [
            { name: 'Dramatic', value: 'dramatic'},
            { name: 'Subtle', value: 'subtle'},
            { name: 'None', value: 'none'}
        ]},
        { title: 'Flowering Season', options: [
            { name: 'Spring', value: 'spring' },
            { name: 'Summer', value: 'summer' },
            { name: 'Fall', value: 'fall' },
            { name: 'Winter', value: 'winter' },
            { name: 'None', value: 'none' }
        ]},
        { title: 'Light Needs', options: [
            { name: 'Full Shade', value: 'full-shade'},
            { name: 'Partial Shade', value: 'partial-shade'},
            { name: 'Filtered Sun', value: 'filtered-sun'},
            { name: 'Partial Sun', value: 'partial-sun'},
            { name: 'Full Sun', value: 'full-sun'}
        ]},
        { title: 'Max Elevation', options: [
            { name: '5,500 feet', value: '5500'},
            { name: '6,000 feet', value: '6000'},
            { name: '6,500 feet', value: '6500'},
            { name: '7,000 feet', value: '7000'},
            { name: '7,500 feet', value: '7500'},
            { name: '8,000 feet', value: '8000'},
            { name: '8,500 feet', value: '8500'},
            { name: '9,000 feet', value: '9000'},
            { name: '10,000 feet', value: '10000'},
            { name: '11,000 feet', value: '11000'}
        ]},
        { title: 'Water Needs', options: [
            { name: 'Low', value: 'low'},
            { name: 'Moderate', value: 'moderate'},
            { name: 'High', value: 'high'}
        ]},
        { title: 'Flower Traits'},
        { title: 'Deciduous Tree Type'},
        { title: 'Mature Height'},
        { title: 'Mature Width'},
        { title: 'Foliage Color'},
        { title: 'Flower Color'},
        { title: 'Problem Uses'},
        { title: 'Rose Classification'},
        { title: 'Rose Color'},
        { title: 'Tree Habit'},
        { title: 'USDA Zone'},
        { title: 'Vine Type'},
        { title: 'Image URL'}
    ]
}
}

export interface PlantAttributes {

    growthRate?: string;

    fallColor?: string;

    floweringSeason?: Array<PlantAttributes.FloweringSeasonEnum>;

    lightNeeds?: Array<PlantAttributes.LightNeedsEnum>;

    maxElevation?: Array<PlantAttributes.MaxElevationEnum>;

    waterNeeds?: PlantAttributes.WaterNeedsEnum;

    flowerTraits?: Array<string>;

    deciduousTreeType?: string;

    matureHeight?: string;

    matureWidth?: string;

    foliageColor?: string;

    flowerColor?: string;

    problemUses?: Array<string>;

    roseClassification?: string;

    roseColor?: string;

    treeHabit?: Array<string>;

    usdaZone?: Array<string>;

    vineType?: string;

    imageUrl?: Array<string>;

}
export namespace PlantAttributes {

    export enum FloweringSeasonEnum {
        Spring = <any> 'Spring',
        Summer = <any> 'Summer',
        Fall = <any> 'Fall',
        Winter = <any> 'Winter',
        None = <any> 'None'
    }
    export enum LightNeedsEnum {
        FullShade = <any> 'Full Shade',
        PartialShade = <any> 'Partial Shade',
        FilteredSun = <any> 'Filtered Sun',
        PartialSun = <any> 'Partial Sun',
        FullSun = <any> 'Full Sun'
    }
    export enum MaxElevationEnum {
        _5500Feet = <any> '5,500 feet',
        _6000Feet = <any> '6,000 feet',
        _6500Feet = <any> '6,500 feet',
        _7000Feet = <any> '7,000 feet',
        _7500Feet = <any> '7,500 feet',
        _8000Feet = <any> '8,000 feet',
        _8500Feet = <any> '8,500 feet',
        _9000Feet = <any> '9,000 feet',
        _10000Feet = <any> '10,000 feet',
        _11000Feet = <any> '11,000 feet'
    }
    export enum WaterNeedsEnum {
        Low = <any> 'Low',
        Moderate = <any> 'Moderate',
        High = <any> 'High'
    }

}
