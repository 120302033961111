import { Component, OnInit } from '@angular/core';
import { DefaultApi } from '../../typescript-angular2-client/api/DefaultApi';
import { Plant } from '../../typescript-angular2-client/model/Plant';

@Component({
  selector: 'app-lookahead',
  templateUrl: './lookahead.component.html',
  styleUrls: ['./lookahead.component.scss'],
  providers: [DefaultApi]
})
export class LookaheadComponent implements OnInit {

    plants : Plant[];

    constructor(private api: DefaultApi) { }

    getPlants(string: string): void {
        if (string.length < 3) {
            this.plants = [];
            return;
        }
        this.api.plantsLookahead(string.toUpperCase()).subscribe(
            response => this.plants = response,
            error => console.log('i guess an error huh', error)
        );
    }


  ngOnInit() {
  }

}
