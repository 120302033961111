import { Component, OnInit } from '@angular/core';
import { DefaultApi, PlantAttributesModel, PlantAttributes } from 'typescript-angular2-client';
import { Plant } from 'typescript-angular2-client/model/Plant';

@Component({
  selector: 'app-plantlist',
  templateUrl: './plantlist.component.html',
  styleUrls: ['./plantlist.component.scss'],
  providers: [DefaultApi]
})
export class PlantlistComponent implements OnInit {

  Plant : Plant;
  UpdatedPlant: Plant;
  TreeTypes: string[];


  okeys = Object.keys;

  public AttributeKeys;
  public sku;
  public isArray = Array.isArray;

  selectedField: any;
  selectedFieldValue: any;



  constructor(
    protected defaultApi: DefaultApi
  ) {}

  setVal() {
    switch (this.selectedField) {
      case 'attributes':
        this.selectedFieldValue = this.Plant.attributes.usdaZone;
        break;
    
      default:
        this.selectedFieldValue = this.Plant[this.selectedField];
        break;
    }

  }

  ngOnInit() {

    
    
    
    this.TreeTypes = [
      "Ornamental Tree",
      "Shade Tree"
    ]

    this.sku = ('asp-qua');
    this.getPlant(this.sku);

    

  }

  getPlant(sku: string) {
    this.defaultApi.plantsSkuGet(sku).subscribe(
      response => this.Plant = response,
      error => console.log('getting a new plant did something wrong', error),
      () => this.afterGetPlant()
    )
  }

  afterGetPlant() {
    
  }

  updateField(sku: string, field: string, value: string) {
    this.defaultApi.plantFieldSet(sku, field, value).subscribe(
      response => this.UpdatedPlant = response,
      error => console.log('error updating plant', error),
      () => this.afterUpdateField(field)
    )
  }

  

  afterUpdateField(field: string) {

    return;
  }
}
