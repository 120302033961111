<div class="no-print">
<!--
	<label>
		<input type="checkbox" checked (change)="saleEnabled = !saleEnabled">
		Sale Sign #1 Enabled?
	</label>
	<label>
		<input type="checkbox" checked (change)="tierEnabled = !tierEnabled">
		QTY Pricing #1 Enabled?
	</label>
	<label>
		<input type="checkbox" checked (change)="saletwoEnabled = !saletwoEnabled">
		Sale Sign #2 Enabled?
	</label>
	<label>
		<input type="checkbox" checked (change)="tiertwoEnabled = !tiertwoEnabled">
		QTY Pricing #2 Enabled?
	</label>
-->
</div>



	
<div class="page" *ngIf="plant">
	
	<div class="up">
		<div class="sale-sign">
			<!-- <app-minisalesign *ngIf="saleEnabled"></app-minisalesign> -->
			<app-minisalesign [plant]="plant"></app-minisalesign>
		</div>
			
		<div class="tier-sign">
			<!-- <app-minitiersign *ngIf="tierEnabled"></app-minitiersign> -->
			<app-minitiersign></app-minitiersign>
		</div>
	</div>

	<div class="up">
		<div class="sale-sign">
			<app-minisalesign [plant]="plant"></app-minisalesign>
			<!-- <app-minisalesign *ngIf="saletwoEnabled"></app-minisalesign> -->
			<!-- <app-minisalesign></app-minisalesign> -->
		</div>
			
		<div class="tier-sign">
			<!-- <app-minitiersign *ngIf="tiertwoEnabled"></app-minitiersign> -->
			<app-minitiersign></app-minitiersign>
		</div>
	</div>
</div>