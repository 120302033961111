import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { DefaultApi } from 'typescript-angular2-client';
import { Plant } from 'typescript-angular2-client/model/Plant';

@Component({
  selector: 'app-salesign',
  encapsulation: ViewEncapsulation.None,
  templateUrl: './salesign.component.html',
  styleUrls: ['./salesign.component.scss'],
  providers: [DefaultApi]
})
export class SalesignComponent implements OnInit {

	template: any;
	plant: any;
	insku: any = 'asp-qua';
	notFound: boolean = false;

	constructor(
		protected defaultApi: DefaultApi
	) { }

	ngOnInit() {
		this.template = {
			names: [
				{ name: 'app-trunksign', text: 'Tree Trunk Sign' },
				{ name: 'app-minisalesign', text: 'Mini Sale Sign'},
				{ name: 'app-locationstrip', text: 'Location Strip Tag'},
				{ name: 'app-saletier', text: 'Sale/Tier Price'},
				{ name: 'app-fallsale', text: '50 Cent Sale'},
				{ name: 'app-utilitysign', text: 'Utility Sign'},
				{ name: 'app-basicsign', text: 'Basic Sign'}
			],
			name: 'app-trunksign'
		}
		this.getPlant(this.insku);
	}

	getPlant(sku: any) {
		this.plant = {};
		this.notFound = false;
		this.plant.price = {
			dollars: '000',
			cents: '99',
			regular: '999',
			percent: '30',
			percentEnabled: false
		};
		this.plant.tier = {
			one: {
			  qty: "3-5",
			  price: "1000"
			},
			two: {
			  qty: "6 or more",
			  price: "1000"
			}
		  }
		this.plant.description = "123";
		this.plant.savings = "1000";
		this.plant.colors = [
			{ name: "Green", value: "green"},
			{ name: "Lime", value: "lime"},
			{ name: "Wheat", value: "wheat"},
			{ name: "Gold", value: "gold"},
			{ name: "Orange", value: "orange"},
			{ name: "Red", value: "red"},
			{ name: "Pink", value: "pink"},
			{ name: "Medium Orchid", value: "mediumorchid"},
			{ name: "Purple", value: "purple"},
			{ name: "Dodger Blue", value: "dodgerblue"},
			{ name: "Light Sky Blue", value: "lightskyblue"},
			{ name: "Floral White", value: "floralwhite"}
		];
		this.plant.color = "lime";
		this.defaultApi.plantsSkuGet(sku).subscribe(
			response => this.plant.response = response,
			error => console.log('some error', error),
			() => this.afterGetPlant()
		);
	}

	

	afterGetPlant() {
		
		if (!this.plant) {
			this.notFound = true;
		}
	}

}
