<div class="no-print">
  <input type="file" (change)="fileChange($event)" accept=".csv" [(ngModel)]="file">
  <div class="readme">
    <p>Imports text/csv formatted data.</p>
    <p>Each line should consist of:  sku , item description , the "both for" price , web color name</p>
    <p>Put data in correct order! column 1 is sku, column 2 is description, 3 is price, 4 is color name.</p>
    <p>Use valid web color names. There is a list <a href="https://en.wikipedia.org/wiki/Web_colors#X11_color_names" target="_blank">HERE</a>.</p>
  </div>
  <button (click)="singleSign()">single sign</button>
  <div class="add-sign" *ngIf="addsign">
    <input type="text" [(ngModel)]="addsign.sku">
    <input type="text" [(ngModel)]="addsign.description">
    <input type="text" [(ngModel)]="addsign.price">
    <!-- <input type="text" [(ngModel)]="addsign.color"> -->
    <select class="color-list" [(ngModel)]="addsign.color">
      <option *ngFor="let color of CSS_COLOR_NAMES" value="{{color}}" [style.background-color]="color" [class.dark-color]="isDark(color)">
        {{color}}
      </option>
    </select>
    <button (click)="addSign(addsign)">Add A Sign</button>
  </div>
</div>

<div class="page" *ngIf="signs && !file">
  <div class="sign" *ngFor="let line of signs; let odd = odd; let even = even; let i = index;" contenteditable="true">
    <div [style.background-color]="line.color" class="sign-title">
      {{line.description}}
    </div>
    <div class="pricing">
      <div class="first-tree">
        <span>1<span class="super">st</span> Tree </span>
        <span class="price">{{line.price - 1 | currency: 'USD':true:'1.0-0' }}<span class="super">{{50}}</span></span>
      </div>
      <div class="second-tree">
        <span>2<span class="super">nd</span> Tree </span>
        <span class="price">50&cent;</span>
      </div>
      <div class="both-trees">
        Both for <span [style.color]="line.color">{{line.price | currency: 'USD':true:'1.0-0'}}</span>
      </div>
      <div class="mix-match">Mix or Match</div>
      <div class="legal">Listed Trees of Equal or Less Reg. Price.&nbsp;&nbsp;<span>SKU:</span>&nbsp;&nbsp;{{line.sku}}</div>
    </div>
    <div class="even" *ngIf="even"></div>
    <div class="break" *ngIf="odd"></div>
    <div class="no-print remover" contenteditable="false">
      <button (click)="removeSign(i)">X</button>
    </div>
  </div>
</div>

<div class="page" *ngIf="file">
  <div class="sign" *ngFor="let line of filedata; let odd = odd; let even = even; let i = index;">
    <div [style.background-color]="line[3]" class="sign-title">
      {{line[1]}}
    </div>
    <div class="pricing">
      <div class="first-tree">
        <span>1<span class="super">st</span> Tree</span>
        <span class="price">{{line[2] - 1 | currency: 'USD':true:'1.0-0' }}<span class="super">{{50}}</span></span>
      </div>
      <div class="second-tree">
        <span>2<span class="super">nd</span> Tree</span>
        <span class="price">50&cent;</span>
      </div>
      <div class="both-trees">
        Both for <span [style.color]="line[3]">{{line[2] | currency: 'USD':true:'1.0-0'}}</span>
      </div>
      <div class="mix-match">Mix or Match</div>
      <div class="legal">Listed Trees of Equal or Less Reg. Price.&nbsp;&nbsp;<span>SKU:</span>&nbsp;&nbsp;{{line[0]}}</div>
    </div>
    <div class="even" *ngIf="even"></div>
    <div class="break" *ngIf="odd"></div>
    <div class="no-print remover" contenteditable="false">
      <button (click)="removeSign(i)">X</button>
    </div>
  </div>
</div>