import { Component, OnInit } from '@angular/core';
import { DefaultApi } from 'typescript-angular2-client';

@Component({
  selector: 'app-data',
  templateUrl: './data.component.html',
  styleUrls: ['./data.component.scss']
})
export class DataComponent implements OnInit {

  file: any;
  filedata: any[];
  groupedData: any[];
  plant: any;
  sku: string;
  saleItems: any[];
  saleValue: any;
  saleValues: number[]

  constructor(public api: DefaultApi) { }



  ngOnInit() {
    this.saleValues = [20,30,40,45,50,60,70,80,90,100,110,120,130,140,150,160,170,180,190,200];
    this.saleValue = 20;
    this.getSaleItems(20);
  }

  getPlant(sku) {
    this.api.testJsonPlantGet(sku).subscribe(
      response => this.plant = response.json()
    )
  }
  getSaleItems(description:number) {
    if (description) {
      this.api.saleItemsGet(description).subscribe(
        response => this.saleItems = response.json()
      )
    } else {
      this.api.saleItemsGet().subscribe(
        response => this.saleItems = response.json()
      )
    }

  }

  fileChange(event) {
    var f = event.target.files[0];
    var reader = new FileReader();

    reader.onload = (e:any) => {
      var data = e.target.result.split(/\r\n|\n/);
      var lines = [];
      var groups = [];
      
      for (let i = 0; i < data.length; i++) {
        var input = data[i].split(',');

        if (groups.indexOf(input[2]) == -1) {
          groups.push(input[2]);
        }

        input.splice(2,1);
        input.splice(2,1);
        input.splice(2,1);
        input.splice(2,1);
        var price = input[2];
        lines.push([input, price]);
      }
      this.groupedData = groups;
      this.filedata = lines;
    }

    reader.readAsText(f, "UTF-8");
  }
}
