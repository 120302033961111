import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-basicsign',
  templateUrl: './basicsign.component.html',
  styleUrls: ['./basicsign.component.scss']
})
export class BasicsignComponent implements OnInit {

  format: {
    types: {name: string, r: number, c: number, ct: number}[]
  };
  type: {
    name: string, r: number, c: number, ct: number
  };
  count: any[];

  orientation: any;
  rows: any;
  cols: any;
  gap: any;
  fontpct: any;
  content: any;
  color: any;
  textcolor: any;
  spacing: any;

  font: {};
  fonts: {}[];

  constructor() { }

  ngOnInit() {
    this.fonts = [
      {name: 'Bevan'},
      {name: 'Hammersmith One'}
    ]
    this.font = this.fonts[0];
    this.format = {
      types: [
        {name: '2x1', r: 2, c: 1, ct: 2},
        {name: '2x2', r: 2, c: 2, ct: 4},
        {name: '8x1', r: 8, c: 1, ct: 8},
        {name: '8x2', r: 8, c: 2, ct: 16},
        {name: '30x30 experiment', r: 30, c: 30, ct: 900}
      ]
    }
    this.type = this.format.types[2];
    this.count = new Array(this.type.ct);
    // this.count = this.type.c * this.type.r;
    this.orientation = 'landscape-print';
    this.rows = 2;
    this.cols = 2;
    this.gap = .0625;
    this.fontpct = 500;
    this.content = "Serious Tree Sale";
    this.color = 'green';
    this.textcolor = 'yellow';
    this.spacing = 0;
  }

  getCount(r, c) {
    return new Array(r * c);
  }
  

}
