import { Component, OnInit } from '@angular/core';
import { Http } from '@angular/http';

@Component({
  selector: 'app-fallsale',
  templateUrl: './fallsale.component.html',
  styleUrls: ['./fallsale.component.scss']
})
export class FallsaleComponent implements OnInit {

  // CSS_COLOR_NAMES = ["AliceBlue","AntiqueWhite","Aqua","Aquamarine","Azure","Beige","Bisque","Black","BlanchedAlmond","Blue","BlueViolet","Brown","BurlyWood","CadetBlue","Chartreuse","Chocolate","Coral","CornflowerBlue","Cornsilk","Crimson","Cyan","DarkBlue","DarkCyan","DarkGoldenRod","DarkGray","DarkGrey","DarkGreen","DarkKhaki","DarkMagenta","DarkOliveGreen","Darkorange","DarkOrchid","DarkRed","DarkSalmon","DarkSeaGreen","DarkSlateBlue","DarkSlateGray","DarkSlateGrey","DarkTurquoise","DarkViolet","DeepPink","DeepSkyBlue","DimGray","DimGrey","DodgerBlue","FireBrick","FloralWhite","ForestGreen","Fuchsia","Gainsboro","GhostWhite","Gold","GoldenRod","Gray","Grey","Green","GreenYellow","HoneyDew","HotPink","IndianRed","Indigo","Ivory","Khaki","Lavender","LavenderBlush","LawnGreen","LemonChiffon","LightBlue","LightCoral","LightCyan","LightGoldenRodYellow","LightGray","LightGrey","LightGreen","LightPink","LightSalmon","LightSeaGreen","LightSkyBlue","LightSlateGray","LightSlateGrey","LightSteelBlue","LightYellow","Lime","LimeGreen","Linen","Magenta","Maroon","MediumAquaMarine","MediumBlue","MediumOrchid","MediumPurple","MediumSeaGreen","MediumSlateBlue","MediumSpringGreen","MediumTurquoise","MediumVioletRed","MidnightBlue","MintCream","MistyRose","Moccasin","NavajoWhite","Navy","OldLace","Olive","OliveDrab","Orange","OrangeRed","Orchid","PaleGoldenRod","PaleGreen","PaleTurquoise","PaleVioletRed","PapayaWhip","PeachPuff","Peru","Pink","Plum","PowderBlue","Purple","Red","RosyBrown","RoyalBlue","SaddleBrown","Salmon","SandyBrown","SeaGreen","SeaShell","Sienna","Silver","SkyBlue","SlateBlue","SlateGray","SlateGrey","Snow","SpringGreen","SteelBlue","Tan","Teal","Thistle","Tomato","Turquoise","Violet","Wheat","White","WhiteSmoke","Yellow","YellowGreen"];
  CSS_COLOR_NAMES = [
    "DarkOliveGreen",
    "Olive",
    "OliveDrab",
    "YellowGreen",
    "LimeGreen",
    "Lime",
    "LawnGreen",
    "Chartreuse",
    "GreenYellow",
    "SpringGreen",
    "MediumSpringGreen",
    "LightGreen",
    "PaleGreen",
    "DarkSeaGreen",
    "MediumAquamarine",
    "MediumSeaGreen",
    "SeaGreen",
    "ForestGreen",
    "Green",
    "DarkGreen",
    "Yellow",
    "LightYellow",
    "LemonChiffon",
    "LightGoldenrodYellow",
    "PapayaWhip",
    "Moccasin",
    "PeachPuff",
    "PaleGoldenrod",
    "Khaki",
    "DarkKhaki",
    "Gold",
    "OrangeRed",
    "Tomato",
    "Coral",
    "DarkOrange",
    "Orange",
    "LightSalmon",
    "Salmon",
    "DarkSalmon",
    "LightCoral",
    "IndianRed",
    "Crimson",
    "FireBrick",
    "DarkRed",
    "Red",
    "Pink",
    "LightPink",
    "HotPink",
    "DeepPink",
    "PaleVioletRed",
    "MediumVioletRed",
    "Cornsilk",
    "BlanchedAlmond",
    "Bisque",
    "NavajoWhite",
    "Wheat",
    "BurlyWood",
    "Tan",
    "RosyBrown",
    "SandyBrown",
    "Goldenrod",
    "DarkGoldenrod",
    "Peru",
    "Chocolate",
    "SaddleBrown",
    "Sienna",
    "Brown",
    "Maroon",
    "Lavender",
    "Thistle",
    "Plum",
    "Violet",
    "Orchid",
    "Fuchsia",
    "Magenta",
    "MediumOrchid",
    "MediumPurple",
    "BlueViolet",
    "DarkViolet",
    "DarkOrchid",
    "DarkMagenta",
    "Purple",
    "Indigo",
    "DarkSlateBlue",
    "SlateBlue",
    "MediumSlateBlue",
    "Aqua",
    "Cyan",
    "LightCyan",
    "PaleTurquoise",
    "Aquamarine",
    "Turquoise",
    "MediumTurquoise",
    "DarkTurquoise",
    "LightSeaGreen",
    "CadetBlue",
    "DarkCyan",
    "Teal",
    "LightSteelBlue",
    "PowderBlue",
    "LightBlue",
    "SkyBlue",
    "LightSkyBlue",
    "DeepSkyBlue",
    "DodgerBlue",
    "CornflowerBlue",
    "SteelBlue",
    "RoyalBlue",
    "Blue",
    "MediumBlue",
    "DarkBlue",
    "Navy",
    "MidnightBlue",
    "White",
    "Snow",
    "Honeydew",
    "MintCream",
    "Azure",
    "AliceBlue",
    "GhostWhite",
    "WhiteSmoke",
    "Seashell",
    "Beige",
    "OldLace",
    "FloralWhite",
    "Ivory",
    "AntiqueWhite",
    "Linen",
    "LavenderBlush",
    "MistyRose",
    "Gainsboro",
    "LightGray",
    "Silver",
    "DarkGray",
    "Gray",
    "DimGray",
    "LightSlateGray",
    "SlateGray",
    "DarkSlateGray",
    "Black"
  ]
  DARK_COLORS = ["Black","Blue","DarkBlue","DarkGreen","SaddleBrown","Sienna","DarkViolet","SlateBlue","Teal","DarkCyan","RoyalBlue","DarkSlateGray","FireBrick","DarkOliveGreen","MediumVioletRed","Brown","DarkMagenta","DarkOrchid","BlueViolet","DarkRed","DarkSlateBlue","Indigo","Maroon","MediumBlue","MidnightBlue","Purple", "Navy"];

  filedata: any[];
  file: any;
  addsign: {
    sku: string,
    description: string,
    price: any,
    color: string
  }
  signs: any[];

  constructor(public http: Http) { }

  ngOnInit() {
    this.signs = [];
  }
  isDark(color) {
    var isdark: boolean;
    if (this.DARK_COLORS.indexOf(color) >= 0) {
      isdark = true;
    }

    return isdark;
  }
  singleSign() {
    this.addsign = {
      sku: "ASP-QUA",
      description: "Description goes here.",
      price: "300",
      color: "DarkOliveGreen"
    }
  }

  addSign(sign){
    var s = {
      sku: sign.sku,
      description: sign.description,
      price: sign.price,
      color: sign.color
    }
    this.signs.push(s);
  }

  removeSign(sign) {
    this.signs.splice(sign, 1);
    if (this.filedata) {
      this.filedata.splice(sign, 1);
    }
  }

  fileChange(event) {
    var f = event.target.files[0];
    var reader = new FileReader();
    
    reader.onload = (e:any)=>{

      // var x = [[],[],[]];
      // var y = ['1', '2', '3'];

      // for (let i = 0; i < x.length; i++) {
      //   x[i] = y;
        
      // }
      // this.filedata = x;

      var dat = e.target.result.split(/\r\n|\n/);
      var lines = [];
      for (let i = 0; i < dat.length; i++) {
        lines.push(dat[i].split(','));
      }

      this.filedata = lines;
      
    }
    reader.readAsText(f, "UTF-8");
  
  }

}
