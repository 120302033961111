import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { HttpModule } from '@angular/http';


import { RouterModule, Routes } from '@angular/router';


import { AppComponent } from './app.component';
import { InfosignComponent } from './infosign/infosign.component';
import { SalesignComponent } from './salesign/salesign.component';
import { TrunksignComponent } from './salesign/trunksign/trunksign.component';
import { MinisalesignComponent } from './salesign/minisalesign/minisalesign.component';
import { LocationstripComponent } from './salesign/locationstrip/locationstrip.component';
import { SaletierComponent } from './salesign/saletier/saletier.component';
import { MinitiersignComponent } from './salesign/minitiersign/minitiersign.component';
import { LookaheadComponent } from './lookahead/lookahead.component';
import { PlantlistComponent } from './plantlist/plantlist.component';
import { ReleasesComponent } from './releases/releases.component';
import { DisplayComponent } from './display/display.component';
import { TflookupComponent } from './tflookup/tflookup.component';
import { EditComponent } from './edit/edit.component';
import { FallsaleComponent } from './salesign/fallsale/fallsale.component';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { DataComponent } from './data/data.component';
import { UtilitysignComponent } from './salesign/utilitysign/utilitysign.component';
import { SalestaxComponent } from './salestax/salestax.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { BasicsignComponent } from './salesign/basicsign/basicsign.component';


const appRoutes: Routes = [
	  { path: 'infosign', component: InfosignComponent },
	  { path: 'salesign', component: SalesignComponent },
    { path: 'lookahead', component: LookaheadComponent },
    { path: 'plants', component: PlantlistComponent },
    { path: 'releases', component: ReleasesComponent },
    { path: 'display', component: DisplayComponent },
    { path: 'lookup', component: TflookupComponent },
    { path: 'edit', component: EditComponent },
    { path: 'data', component: DataComponent },
    { path: 'salestax', component: SalestaxComponent }
]


@NgModule({
  declarations: [
    AppComponent,
    InfosignComponent,
    SalesignComponent,
    TrunksignComponent,
    MinisalesignComponent,
    LocationstripComponent,
    SaletierComponent,
    MinitiersignComponent,
    LookaheadComponent,
    PlantlistComponent,
    ReleasesComponent,
    DisplayComponent,
    TflookupComponent,
    EditComponent,
    FallsaleComponent,
    DataComponent,
    UtilitysignComponent,
    SalestaxComponent,
    BasicsignComponent
  ],
  imports: [
    DragDropModule,
    BrowserModule,
    FormsModule,
    HttpModule,
    RouterModule.forRoot(appRoutes),
    BrowserAnimationsModule
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule {

  
}
