import { Component, Input, ViewChild, OnInit } from '@angular/core';
import { MinisalesignComponent } from '../minisalesign/minisalesign.component';

@Component({
  selector: 'app-saletier',
  templateUrl: './saletier.component.html',
  styleUrls: ['./saletier.component.scss']
})
export class SaletierComponent implements OnInit {

	saleEnabled = true;
	tierEnabled = true;
	saletwoEnabled = true;
  tiertwoEnabled = true;
  @Input() plant;

  
  constructor() { }
  
  ngOnInit() {


  }


}
