import { Component, OnInit } from '@angular/core';
import { DefaultApi, Plant } from 'typescript-angular2-client';

@Component({
  selector: 'app-edit',
  templateUrl: './edit.component.html',
  styleUrls: ['./edit.component.scss']
})
export class EditComponent implements OnInit {

  Plant: Plant;
  UpdatedPlant: Plant;

  public sku;
  public editName;

  constructor(private api: DefaultApi) { }

  ngOnInit() {
  }

  getPlant(sku: string) {
    this.api.plantsSkuGet(sku).subscribe(
      response => this.Plant = response,
      error => console.log('getting a new plant did something wrong', error),
      () => this.afterGetPlant()
    )
  }

  afterGetPlant() {
    
  }

  setName(name: string) {
    
    this.api.plantFieldSet(this.Plant.sku, 'name', name).subscribe(
      response => this.Plant = response,
      error => console.log('setting name is bugged ohno', error),
      () => this.afterSetName()
    )
  }

  afterSetName() {

  }

}
