import { Component, Input, OnInit, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'app-trunksign',
  // encapsulation: ViewEncapsulation.None,
  templateUrl: './trunksign.component.html',
  styleUrls: ['./trunksign.component.scss']
})
export class TrunksignComponent implements OnInit {

	fulltag = true;
	edit = false;
	@Input() plant: any;

	constructor() { }

	ngOnInit() {

		// this.plant.originalPrice = "1000";
		
		// this.plant.salePrice = {
		// 	dollars: "1000"
		// };

		
		/*
		this.plant = {
			name: "",
			description: "",
			originalPrice: "1000",
			salePrice: {
				dollars: "1000"
			},
			savings: "1000",
			sku: "MAP-RED25C175",
			colors: [
				{ name: "Green", value: "green"},
				{ name: "Lime", value: "lime"},
				{ name: "Wheat", value: "wheat"},
				{ name: "Gold", value: "gold"},
				{ name: "Orange", value: "orange"},
				{ name: "Red", value: "red"},
				{ name: "Pink", value: "pink"},
				{ name: "Medium Orchid", value: "mediumorchid"},
				{ name: "Purple", value: "purple"},
				{ name: "Dodger Blue", value: "dodgerblue"},
				{ name: "Light Sky Blue", value: "lightskyblue"},
				{ name: "Floral White", value: "floralwhite"}
			],
			color: "lime"
		}
		*/
	}

}
