import { Component, OnInit } from '@angular/core';
import { SalestaxService } from '../salestax.service';
import { Response } from '@angular/http';
import { Observable } from 'rxjs/Observable';

@Component({
  selector: 'app-salestax',
  templateUrl: './salestax.component.html',
  styleUrls: ['./salestax.component.scss']
})
export class SalestaxComponent implements OnInit {

  response: any;
  address: {
    line1: string,
    city: string,
    region: string,
    postalCode: string,
    country: string
  };
  
  rates: any[];
  totalRate: string;
  okeys = Object.keys;

  constructor(private tax: SalestaxService) { }


  ngOnInit() {
    this.address = {
      line1: '11868 Mineral Road',
      city: 'longmont',
      region: 'co',
      postalCode: '80504',
      country: 'US'
    }
    this.rates = [];
  }
  
  test() {
    this.tax.test().subscribe(
      response => this.response = JSON.stringify(response),
      error => console.log(error)
    );
  }

  taxByAddress() {
    this.tax.taxByAddress(this.address).subscribe(
      response => this.response = response,
      error => this.response = JSON.stringify(error),
      () => this.after()
    )
  }

  after() {
    // console.log(JSON.parse(this.response._body));
    this.rates = JSON.parse(this.response._body).rates;
    this.totalRate = JSON.parse(this.response._body).totalRate;
    return;
  }
  
  ping() {
    this.tax.ping().subscribe(
      response => this.response = JSON.stringify(response),
      error => console.log(error)
    )
  }

  getSubscriptions() {
    this.tax.getSubscriptions().subscribe(
      response => this.response = JSON.stringify(response),
      error => console.log(error)
    )
  }

  getJurisdictions() {
    this.tax.getJurisdictions(this.address).subscribe(
      response => this.response = response,
      error => this.response = JSON.stringify(error)
    )
  }

}
