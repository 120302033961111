<div class="no-print">
  <select [(ngModel)]="type">
    <option *ngFor="let type of format.types" [ngValue]="type">
      {{type.name}}
    </option>
  </select>
  <select [(ngModel)]="font">
    <option *ngFor="let font of fonts" [ngValue]="font">
      {{font.name}}
    </option>
  </select>
  <input type="number" [(ngModel)]="type.c">
  <input type="number" [(ngModel)]="type.r">
  <select [(ngModel)]="orientation">
    <option [ngValue]="'portrait-print'">Portrait</option>
    <option [ngValue]="'landscape-print'">Landscape</option>
  </select>
  <input type="number" step="0.005" [(ngModel)]="gap">
  <input type="text" [(ngModel)]="content">
  <input type="range" [(ngModel)]="fontpct" min="0" max="1000">
  <input type="color" [(ngModel)]="color">
  <input type="color" [(ngModel)]="textcolor">
  <input type="range" [(ngModel)]="spacing" min="0" max="100">
</div>
<div id="page" [class]="orientation">
  <div *ngIf="type" class="g" [style.font-size.%]="fontpct" [style.grid-gap.in]="gap" [ngStyle]="{ 'grid-template-columns': 'repeat(' + type.c + ', minmax(0, 1fr))', 'grid-template-rows': 'repeat(' + type.r + ', minmax(0, 1fr))' }">
    <div *ngFor="let item of getCount(type.r, type.c)" class="content" [style.background]="color" [style.color]="textcolor">
      <span [style.letter-spacing.px]="spacing" [style.font-family]="font.name">{{content}}</span>
    </div>
  </div>
</div>