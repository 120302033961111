<div id="0.1">
  <p>0.1 - First Documented Release</p>
  <ul>
    <li>Release Notes page added</li>
    <li>Plant list page exists but has limited functionality.</li>
    <div id="0.1.1">
      <p>0.1.1 - Plant Image updates and lookup tools</p>
      <ul>
        <li>(API) imageUrl now array</li>
        <li>(API) added new methods for object import via tf.com and s3</li>
        <li>Info signs - Import Plant and Import Image function. If plant not found, try to import.</li>
        <li>Info signs - Can now select which image to use.</li>
        <li>When Importing plants or images, you may have to hit "GO" again for things to load</li>
        <li>Lookup Page - Generic lookup tools, not useful for end users.</li>
      </ul>
    </div>
    <div id="0.1.2">
      <p>0.1.2 - Unreleased - update to use s3 images instead of tf.com - bandwidth issues</p>
    </div>
    <div id="0.1.3">
      <p>0.1.3 - Unreleased - Migrate to https</p>
    </div>
    <div id="0.1.4">
      <p>0.1.4 - Released - Fall Sale 50 cent signs</p>
      <p>0.1.4.1 - Released - Fall 50 cent signs tidied up, color names added, remover button added</p>
    </div>
  </ul>
</div>
<div id="0.2">
  <p>0.2 - Update to Latest Angular</p>
  <ul>
    <li>Updated to Angular 7</li>
    <li>Fixed old dependencies</li>
    <li>Added Angular Material</li>
    <li>Removed Draggable directive - switched to DragDropModule from CDK</li>
    <li>Tidied up Import form</li>
    <li>Added 'Raleway' font</li>
  </ul>
</div>