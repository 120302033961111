<div class="flip-container">
	<input type="checkbox" title="Click to Edit Values">
	<div class="flipper">
		<div class="front" *ngIf="plant.response">
			<div class="page">
				<div class="sale">Sale</div>
				<div class="price">
					<div *ngIf="!plant.price.percentEnabled">
						<span>$</span>
						<span [ngClass]="{'long':plant.price.dollars.length>3}">{{plant.price.dollars}}</span>
						<span>{{plant.price.cents}}</span>
						<span>Each</span>
					</div>
					<div *ngIf="plant.price.percentEnabled">
						<span></span>
						<span>{{plant.price.percent}}</span>
						<span>%</span>
						<span class="percent">Off</span>
					</div>
				</div>
				<div class="details">
					<p>{{plant.response.name}}</p>
					<p>{{plant.response.details}}</p>
					<p>Reg. ${{plant.price.regular}}.95 • {{plant.response.sku}}</p>
				</div>
			</div>
			
		</div>
		<div class="back no-print">
			<div>
				<label>
					<span *ngIf="notFound">Plant Not Found</span>
					<input placeholder="Enter SKU to Override and Press Enter" [(ngModel)]="sku" (keyup.enter)="getPlant(sku)">
				</label>
			</div>
			<div *ngIf="plant.response">
				<div>
					<label>
						Sale Price
						<input [(ngModel)]="plant.price.dollars">
					</label>
				</div>
				<div>
					<label>
						Percentage Savings
						<input [(ngModel)]="plant.price.percent">
					</label>
					<label>
						Enabled?
						<input type="checkbox" [(ngModel)]="plant.price.percentEnabled">
					</label>
				</div>
				<div>
					<label>
						Regular Price
						<input [(ngModel)]="plant.price.regular">
					</label>
				</div>
				<!--
				<div>
					<label>
						Cents Price
						<input [(ngModel)]="price.cents" maxlength="2">
					</label>
				</div>
				-->
				<div>
					<label>
						Plant Name
						<input [(ngModel)]="plant.response.name">
					</label>
				</div>
				<div>
					<label>
						Plant SKU
						<input [(ngModel)]="plant.response.sku">
					</label>
				</div>
				<div>
					<label>
						Plant Details
						<input [(ngModel)]="plant.response.details">
					</label>
				</div>
			</div>
		</div>
	</div>
</div>