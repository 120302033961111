import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-utilitysign',
  templateUrl: './utilitysign.component.html',
  styleUrls: ['./utilitysign.component.scss']
})
export class UtilitysignComponent implements OnInit {

  @Input() plant;

  constructor() { }

  ngOnInit() {
  }

}
