<div class="flip-container">
	<input type="checkbox" [(ngModel)]="edit">
	<span class="edit no-print" (click)="edit = !edit">EDIT</span>
	<div class="flipper">
		<div class="front" *ngIf="plant.response">
			<div class="page">

				<div class="sidebox" *ngIf="fulltag">
					<h1 [ngClass]="{'long':plant.response.name.length>15}">{{plant.response.name}}</h1>
					<p>{{plant.description}}</p>
					<h2>
						<span>Sale Now!</span>
						<span>
							<span class="dollars" [style.color]="plant.color">{{plant.price.dollars}}</span>
							<span [style.color]="plant.color">99</span>
						</span>
					</h2>
				</div>
				<div class="centerbox" [style.background-color]="plant.color">
					<div class="logobox">
						<img src="/assets/images/tree-farm-logo.gif">
					</div>
					<div class="savingsbox">
						<h3>
							<span>Save</span>
							<span class="dollars">{{plant.savings}}</span>
						</h3>
						<p>Off The Regular Price of Each Tree</p>
						<p>{{plant.response.sku}}</p>
					</div>
				</div>
				<div class="sidebox">
					<h1 [ngClass]="{'long':plant.response.name.length>15}">{{plant.response.name}}</h1>
					<p>{{plant.description}}</p>
					<h2>
						<span>Sale Now!</span>
						<span>
							<span class="dollars" [style.color]="plant.color">{{plant.price.dollars}}</span>
							<span [style.color]="plant.color">99</span>
						</span>
					</h2>
				</div>

			</div>
			
		</div>
		
		<div class="back no-print" *ngIf="plant.response">
			<label>
				<input type="checkbox" [checked]="fulltag" (change)="fulltag = !fulltag">
				Full Tree tag?
			</label>
			<label>
				<input type="text" [(ngModel)]="plant.response.name" name="Plant Name Goes Here">
				Title / Plant Name
			</label>
			<label>
				<input type="text" [(ngModel)]="plant.description" name="Description">
				Extended Description / Size / Price
			</label>
			<label>
				<input type="text" [(ngModel)]="plant.price.dollars" name="Sale Price">
				Sale Price (Dollars)
			</label>
			<label>
				<input type="text" [(ngModel)]="plant.savings" name="Savings">
				Savings
			</label>
			<label>
				<input type="text" [(ngModel)]="plant.response.sku" name="SKU">
				SKU
			</label>
			<label>
				<select [(ngModel)]="plant.color">
					<option *ngFor="let color of plant.colors" [style.background-color]="color.value" [value]="color.value">
						{{color.name}}
					</option>
				</select>
				<span class="swatch" [style.background-color]="plant.color"></span>
			</label>
		</div>
	</div>
</div>

