<div class="nav-menu no-print">
	<a routerLink="/" routerLinkActive="active">Home</a>
	<a routerLink="/infosign" routerLinkActive="active">Plant Info Signs</a>
	<a routerLink="/salesign" routerLinkActive="active">Sale Signs</a>
	<a routerLink="/lookup" routerLinkActive="active">lookup</a>
	<a routerLink="/salestax" routerLinkActive="active">Tax Lookup</a>

	<!-- <a routerLink="/edit" routerLinkActive="active">edit</a> -->
	<span class="title">The Tree Farm Sign Printing <a class="info" title="v2.0" routerLink="/releases">!</a></span>
</div>
<router-outlet></router-outlet>