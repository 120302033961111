/**
 * Plants API
 * The Tree Farm Plant Information API - All information ©2017 Arbortanics Inc. Unauthorized Access Prohibited
 *
 * OpenAPI spec version: 0.1.0
 * Contact: pljspahn@gmail.com
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { Http, Headers, URLSearchParams }                    from '@angular/http';
import { RequestMethod, RequestOptions, RequestOptionsArgs } from '@angular/http';
import { Response, ResponseContentType }                     from '@angular/http';

import { Observable }                                        from 'rxjs/Observable';
import 'rxjs/add/operator/map';

import * as models                                           from '../model/models';
import { BASE_PATH }                                         from '../variables';
import { Configuration }                                     from '../configuration';

/* tslint:disable:no-unused-variable member-ordering */


@Injectable()
export class DefaultApi {
    // protected basePath = 'http://localhost:8080';
    protected basePath = 'http://api.thetreefarm.com:8080';
    // protected basePath = 'http://mongo.prestonspahn.com:8080';
    // protected basePath = 'https://localhost:443';
    protected altPath = 'http://192.168.1.43:8080';
    public defaultHeaders: Headers = new Headers();
    public configuration: Configuration = new Configuration();

    


    constructor(protected http: Http, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
        if (basePath) {
            this.basePath = basePath;
        }
        if (configuration) {
            this.configuration = configuration;
        }
    }

    /**
     * 
     * Extends object by coping non-existing properties.
     * @param objA object to be extended
     * @param objB source object
     */
    private extendObj<T1,T2>(objA: T1, objB: T2) {
        for(let key in objB){
            if(objB.hasOwnProperty(key)){
                (objA as any)[key] = (objB as any)[key];
            }
        }
        return <T1&T2>objA;
    }



    /**
     * 
     * 
     */
    public plantsGet(extraHttpRequestParams?: any): Observable<Array<models.Plant>> {
        return this.plantsGetWithHttpInfo(extraHttpRequestParams)
            .map((response: Response) => {
                if (response.status === 204) {
                    return undefined;
                } else {
                    return response.json();
                }
            });
    }

    public plantFieldSet(sku: string, field: string, value: string, extraHttpRequestParams?: any): Observable<models.Plant> {
        return this.plantSetFieldWithHttpInfo(sku, field, value, extraHttpRequestParams)
            .map((response: Response) => {
                if (response.status === 204) {
                    return undefined;
                } else {
                    return response.json();
                }
            })
    }

    public plantSetFieldWithHttpInfo(sku: string, field: string, value: string, extraHttpRequestParams?: any): Observable<Response> {
        const path = this.basePath + `/plants/setfield/${sku}/${field}=${value}`;
        let headers = new Headers(this.defaultHeaders.toJSON()); // https://github.com/angular/angular/issues/6845

        let requestOptions: RequestOptionsArgs = new RequestOptions({
            method: RequestMethod.Get,
            headers: headers
        })
        return this.http.request(path, requestOptions);
    }


    public plantImage(sku: string, extraHttpRequestParams?: any): Observable<Response> {
        const path = this.basePath + `/tfimage/${sku}`;
        let headers = new Headers(this.defaultHeaders.toJSON());
        let requestOptions: RequestOptionsArgs = new RequestOptions({
            method: RequestMethod.Get,
            headers: headers
        })
        return this.http.request(path, requestOptions);
    }

    /**
     * 
     * 
     * @param sku 
     */
    public plantsSkuGet(sku: string, extraHttpRequestParams?: any): Observable<models.Plant> {
        return this.plantsSkuGetWithHttpInfo(sku, extraHttpRequestParams)
            .map((response: Response) => {
                if (response.status === 204) {
                    return undefined;
                } else {
                    return response.json();
                }
            });
    }

    public plantsLookahead(string: string): Observable<Array<models.Plant>> {
        const path = this.altPath + `/plants/lookahead/${string}`;
        let headers = new Headers(this.defaultHeaders.toJSON());

        
        // authentication (UserSecurity) required
        // http basic authentication required
        if (this.configuration.username || this.configuration.password)
        {
            headers.set('Authorization', 'Basic ' + btoa(this.configuration.username + ':' + this.configuration.password));
        }

        let requestOptions: RequestOptionsArgs = new RequestOptions({
            method: RequestMethod.Get,
            headers: headers
        });
    
        return this.http.get(path).map((response: Response) => {
            return response.json();  
        });
    }

    /**
     * 
     * 
     */
    public plantsGetWithHttpInfo(extraHttpRequestParams?: any): Observable<Response> {
        const path = this.basePath + `/plants`;

        let queryParameters = new URLSearchParams();
        let headers = new Headers(this.defaultHeaders.toJSON()); // https://github.com/angular/angular/issues/6845

        // to determine the Content-Type header
        let consumes: string[] = [
        ];

        // to determine the Accept header
        let produces: string[] = [
            'application/json'
        ];
        
        // authentication (UserSecurity) required
        // http basic authentication required
        if (this.configuration.username || this.configuration.password)
        {
            headers.set('Authorization', 'Basic ' + btoa(this.configuration.username + ':' + this.configuration.password));
        }

        
        let requestOptions: RequestOptionsArgs = new RequestOptions({
            method: RequestMethod.Get,
            headers: headers,
            search: queryParameters
        });
        
        // https://github.com/swagger-api/swagger-codegen/issues/4037
        if (extraHttpRequestParams) {
            requestOptions = this.extendObj(requestOptions, extraHttpRequestParams);
        }

        return this.http.request(path, requestOptions);
    }

    public plantMagentoGet(sku: string, extraHttpRequestParams?: any): Observable<Response> {
        const path = this.basePath + `/import/${sku}`;
        let queryParameters = new URLSearchParams();
        let headers = new Headers(this.defaultHeaders.toJSON());
        if (sku === null || sku === undefined) {
            throw new Error('sku was not given bro.');
        }
        let consumes: string[] = [];
        let produces: string[] = [
            'application/json'
        ];
        let requestOptions: RequestOptionsArgs = new RequestOptions({
            method: RequestMethod.Get,
            headers: headers,
            search: queryParameters
        });

        if (extraHttpRequestParams) {
            requestOptions = this.extendObj(requestOptions, extraHttpRequestParams);
        }
        return this.http.request(path, requestOptions);
    }

    
    public testJsonPlantGet(sku: string, extraHttpRequestParams?: any): Observable<Response> {
        const path = 'http://localhost:3000/plants' + `?sku_like=${sku}`;
        let queryParameters = new URLSearchParams();
        let headers = new Headers(this.defaultHeaders.toJSON());
        if (sku === null || sku === undefined) {
            throw new Error('sku was not given bro.');
        }
        let consumes: string[] = [];
        let produces: string[] = [
            'application/json'
        ];
        let requestOptions: RequestOptionsArgs = new RequestOptions({
            method: RequestMethod.Get,
            headers: headers,
            search: queryParameters
        })
        if (extraHttpRequestParams) {
            requestOptions = this.extendObj(requestOptions, extraHttpRequestParams);
        }
        
        return this.http.request(path, requestOptions);
    }

    public saleItemsGet(description?: number, extraHttpRequestParams?: any):Observable<Response> {
        var path = '';
        if (description) {
            path = 'http://localhost:3000/saleItems?price='+description;
        } else {
            path = 'http://localhost:3000/saleItems';
        }

        let queryParameters = new URLSearchParams();
        let headers = new Headers(this.defaultHeaders.toJSON());
        let requestOptions: RequestOptionsArgs = new RequestOptions({
            method: RequestMethod.Get,
            headers: headers,
            search: queryParameters
        })
        if (extraHttpRequestParams) {
            requestOptions = this.extendObj(requestOptions, extraHttpRequestParams);
        }

        return this.http.request(path, requestOptions);
    }

    /**
     * 
     * 
     * @param sku 
     */
    public plantsSkuGetWithHttpInfo(sku: string, extraHttpRequestParams?: any): Observable<Response> {
        const path = this.basePath + `/plants/${sku}`;

        let queryParameters = new URLSearchParams();
        let headers = new Headers(this.defaultHeaders.toJSON()); // https://github.com/angular/angular/issues/6845

        // verify required parameter 'sku' is not null or undefined
        if (sku === null || sku === undefined) {
            throw new Error('Required parameter sku was null or undefined when calling plantsSkuGet.');
        }


        // to determine the Content-Type header
        let consumes: string[] = [
        ];

        // to determine the Accept header
        let produces: string[] = [
            'application/json'
        ];
        
        // authentication (UserSecurity) required
        // http basic authentication required
        if (this.configuration.username || this.configuration.password)
        {
            headers.set('Authorization', 'Basic ' + btoa(this.configuration.username + ':' + this.configuration.password));
        }
            

        
        let requestOptions: RequestOptionsArgs = new RequestOptions({
            method: RequestMethod.Get,
            headers: headers,
            search: queryParameters
        });
        
        // https://github.com/swagger-api/swagger-codegen/issues/4037
        if (extraHttpRequestParams) {
            requestOptions = this.extendObj(requestOptions, extraHttpRequestParams);
        }
        return this.http.request(path, requestOptions);
    }

}
