import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-minitiersign',
  templateUrl: './minitiersign.component.html',
  styleUrls: ['./minitiersign.component.scss']
})
export class MinitiersignComponent implements OnInit {


	@Input() plant: any;
	tier: any;


  constructor() { }

	ngOnInit() {
		this.tier = {
			one: {
				qty: "3-5",
				price: "1000"
			},
			two: {
				qty: "6 or more",
				price: "1000"
			}
		}
	}

}
