<button (click)="test()">Test</button>


<button (click)="ping()">Ping</button>

<button (click)="getSubscriptions()">Subs</button>


<div *ngIf="address">

    <br>
    <input type="text" [(ngModel)]="address.line1" placeholder="Address Line 1">
    <br>
    <input type="text" [(ngModel)]="address.city" placeholder="City">
    <br>
    <input type="text" [(ngModel)]="address.region" placeholder="State">
    <br>
    <input type="text" [(ngModel)]="address.postalCode" placeholder="ZIP">
    <br>
    <input type="text" [(ngModel)]="address.country" placeholder="Country">
    <br>
    
    <button (click)="taxByAddress()">Get Taxes!</button>
    <button (click)="getJurisdictions()">Get Jurisdictions!</button>
</div>

<div *ngIf="response">
    Total Sales Tax - {{totalRate|percent}}
    <div *ngFor="let rate of rates">
        {{rate.name}} - {{rate.rate|percent}}
    </div>
</div>
<br>
{{response}}