<button (click)="getSaleItems(saleValue)">go</button>
<select [(ngModel)]="saleValue">
  <option *ngFor="let val of saleValues" value="{{val}}">
    {{val}}
  </option>
</select>

<br>
<div *ngIf="saleItems" class="sale-items">
  <img src="../assets/images/tf-tree.png">
  <div class="first-tree">1st Tree ${{saleItems[0].price - .50|number:'1.2-2'}}</div>
  <div class="second-tree">2nd Tree 50&cent;</div>
  <div class="both-for">Both for ${{saleItems[0].price}}</div>
  <ul>
    <li *ngFor="let item of saleItems">
      {{item.description}} ...
    </li>
  </ul>
</div>




<!--
<input type="text" [(ngModel)]="sku" (keyup.enter)="getPlant(sku)">
<div *ngIf="plant">
  {{plant[0].description}}
  <br>
</div>


<input type="file" (change)="fileChange($event)" accept=".csv" [(ngModel)]="file">

<div *ngIf="file">
  <div *ngFor="let line of filedata;">
    
    <div *ngFor="let group of groupedData">
      <h1>{{group}}</h1>
      <div *ngIf="group == line[2]">
        <div class="group">
        </div>
      </div>
    </div>

  </div>
</div>

<div *ngIf="file">
  <div *ngFor="let line of filedata; let i = index">

    <div *ngFor="let detail of line[0]">
      {{detail}}
    </div>
    <br>
  </div>
</div>


  
  <div *ngIf="file">
    <div *ngFor="let line of filedata; let odd = odd; let even = even; let i = index;">
      <p>{{line[0]}}</p>
      <p>{{line[1]}}</p>
      <p>{{line[2]}}</p>
      <p>{{line[3]}}</p>
      <p>{{line[4]}}</p>
      <p>{{line[5]}}</p>
      <p>{{line[6]}}</p>
      <br>
    </div>
  </div>
-->