import { Component, ViewEncapsulation } from '@angular/core';
import { DefaultApi } from '../typescript-angular2-client/api/DefaultApi';
import { SalestaxService } from './salestax.service';
import { Plant } from '../typescript-angular2-client/model/Plant';

@Component({
  selector: 'app-root',
  encapsulation: ViewEncapsulation.None,
  providers: [DefaultApi, SalestaxService ],
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss','../assets/css/weather-icons.css']
})
export class AppComponent {


	constructor() {
		
	}
	/*
	plants: Plant[];
	plant: Plant;
		
	constructor(private defaultApi: DefaultApi){

	}


	getPlant(sku: any) {
		// console.log(event);
		this.defaultApi.plantsSkuGet(sku).subscribe(
			response => this.plant = response[0]
		);
	}


	getPlants(): void {
		this.defaultApi.plantsGet().subscribe(
			response => this.plants = response
		);
	}


	*/
	ngOnInit(): void {
		
	}
	// title = 'app works!';

  
}

