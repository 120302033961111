import { Component, Input, Output, OnInit } from '@angular/core';
import { Plant } from '../../../typescript-angular2-client/model/Plant';
import { DefaultApi } from 'typescript-angular2-client';
import { NgModel } from '@angular/forms';

@Component({
  selector: 'app-minisalesign',
  templateUrl: './minisalesign.component.html',
  styleUrls: ['./minisalesign.component.scss'],
  providers: [DefaultApi]
})
export class MinisalesignComponent implements OnInit {

	notFound: boolean = false;
	override: boolean = false;
	sku: string;
	// plant: any;
	@Input() plant;
	// price: any;

	constructor(
		private defaultApi: DefaultApi
		) {}

	ngOnInit() {
		//this.setPriceObj();
	}
	
	setPriceObj() {
		this.plant.price = {
			dollars: '000',
			cents: '99',
			regular: '199',
			percent: '30'
		}

	}
	
	getPlant(sku: string) {
		this.plant = {};
		this.override = true;
		this.setPriceObj();
		this.notFound = false;


		this.defaultApi.plantsSkuGet(sku).subscribe(
			response => this.plant.response = response,
			error => console.log('some error', error),
			() => this.afterGetPlant()
		);
	}
	
	afterGetPlant() {
		if (!this.plant) {
			this.notFound = true;
			this.plant = {};
		}

	}

}
