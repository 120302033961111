<input (keyup.enter)="getPlant(sku)" [(ngModel)]="sku">

<div *ngIf="Plant">
  <input type="checkbox" [(ngModel)]="editName">
  <div *ngIf="!editName">
    {{Plant.name}}
  </div>
  <div *ngIf="editName">
    <input type="text" [(ngModel)]="Plant.name">
  </div>


</div>