<input (keyup.enter)="getPlant(sku.value)" type="text" value="asp-qua" #sku autofocus>
<button (click)="getPlant(sku.value)">GO</button>
	
<span class="no-print">
	Enter plant base SKU and click GO!
</span>
<div class="no-print" *ngIf="!selectedImage">
	<button (click)="missingImage(sku.value)">Import Image?</button>
	{{imageResponse}}
</div>
<div class="no-print" *ngIf="!plant && loaded">
	<button (click)="missingPlant(sku.value)">Import Plant?</button>
	{{plantResponse}}
</div>
<div class="imageSizer no-print">
	<button (mousedown)="setImageSize('+')" >Image Size +</button>
	<button (mousedown)="setImageSize('-')" >Image Size -</button>
</div>

<div *ngIf="plant" class="no-print">
	<select [(ngModel)]="selectedImage">
		<option *ngFor="let url of plant.attributes.imageUrl; let i = index" [value]="i" >
			{{url}}
		</option>
	</select>


	
	<div *ngIf="addedPrice">
		<input type="checkbox" id="addedPrice" [(ngModel)]="addedPrice.enabled">
		<label for="addedPrice">Add Prices</label>

		<div *ngIf="addedPrice.enabled">
			<textarea rows="3" [(ngModel)]="addedPrice.text"></textarea>
			<input type="text" [(ngModel)]="addedPrice.price">
			<input type="text" [(ngModel)]="addedPrice.cents">
			<select class="color-list" [(ngModel)]="addedPrice.color">
				<option *ngFor="let color of CSS_COLOR_NAMES" value="{{color}}" [style.background-color]="color" [class.dark-color]="isDark(color)">
				  {{color}}
				</option>
			</select>
			<button (mousedown)="setFontSize('+')">Font Size +</button>
			<button (mousedown)="setFontSize('-')">Font Size -</button>
		</div>
	</div>

</div>

<div class="no-print" *ngIf="plant">
	<button (mousedown)="html2jpg()">Save JPG</button>
	<button (mousedown)="html2png()">Save PNG</button>
</div>

<div *ngIf="plant" class="page" id="docpage">

	<div class="title">
		<h1 (keyup)="checkLength($event.target.textContent)" contenteditable="true" [className]="nameClass">{{plant.name}}</h1>
		<!--
			<h1 contenteditable="true" *ngIf="plant.name.length > 15" class="long-name">{{plant.name}}</h1>
			<h1 contenteditable="true" *ngIf="plant.name.length <= 15" class="short-name">{{plant.name}}</h1>
		-->
		<h2>{{plant.botanicName}}</h2>
	</div>


	<!--
	<img *ngIf="selectedImage" style="width: 43%; height: auto;" [style.width.%]="imageSize" class="main-image" src="http://www.thetreefarm.com/media/catalog/product/{{selectedImage|slice:0:1|lowercase}}/{{selectedImage|slice:1:2|lowercase}}/{{selectedImage}}">
-->
	<img style="width: 43%; height: auto;" [style.width.%]="imageSize" class="main-image" src="http://api.thetreefarm.com:3000/tfdotcom/{{plant.sku}}/{{selectedImage}}">
	

	<ul class="attributes" contenteditable="true">
		<li *ngIf="plant.attributes.deciduousTreeType" >{{plant.attributes.deciduousTreeType}}</li>
		<li *ngIf="plant.attributes.growthRate" >Growth Rate: {{plant.attributes.growthRate}}</li>
		<li *ngIf="plant.attributes.waterNeeds" >Water Needs: {{plant.attributes.waterNeeds}}</li>


		<li class="sub-range">
			<span *ngFor="let light of plant.attributes.lightNeeds; let f=first; let l=last">
				<span *ngIf="f == true && l == true">
					<span *ngIf="f">Light Needs: {{light}}</span>
				</span>
				<span *ngIf="f == true && l != true">
					Light Needs: {{light}}
				</span>
				<span *ngIf="l == true && f != true">
					 to {{light}}
				</span>
				<!--
					<span *ngIf="f">Light Needs: {{light}}</span><span *ngIf="l">to {{light}}</span>
					-->
				
			</span>
		</li>

		<li class="sub-range">
			<span *ngFor="let elevation of plant.attributes.maxElevation; let f=first; let l=last;">
				<span *ngIf="f">Elev. Range: {{elevation}} - </span><span *ngIf="l">{{elevation}}</span>
			</span>
		</li>

		<li class="sub-range">
			<span *ngFor="let zone of plant.attributes.usdaZone; let f=first; let l=last;">
				<span *ngIf="f">USDA Zone Range: {{zone}} - </span><span *ngIf="l">{{zone}}</span>
			</span>
		</li>

		<!-- <li *ngFor="let size of plant.attributes.fruitTreeSize">{{size}}</li> -->
		
		<li *ngIf="plant.attributes.matureHeight" >Mature Height: {{plant.attributes.matureHeight}}</li>
		<li *ngIf="plant.attributes.matureWidth" >Mature Width: {{plant.attributes.matureWidth}}</li>
		<li *ngIf="plant.attributes.fallColor" >Fall Color: {{plant.attributes.fallColor}}</li>

		<!--
		-->
		<li *ngIf="plant.attributes.treeHabit?.length > 0" >Tree Habit: {{plant.attributes.treeHabit.join(", ")}}</li>
		<li *ngIf="plant.attributes.foliageColor" >Foliage Color: {{plant.attributes.foliageColor}}</li>
		<li *ngIf="plant.attributes.flowerColor" >Flower Color: {{plant.attributes.flowerColor}}</li>
		<!-- <li *ngIf="plant.attributes.floweringSeason?.length > 0 && plant.attributes.floweringSeason != 'None'" >Flowering Season: {{plant.attributes.floweringSeason.join(", ")}}</li> -->
		<li *ngIf="plant.attributes.problemUses?.length > 0" >Problem Uses: {{plant.attributes.problemUses.join(", ")}}</li>
		<li *ngIf="plant.attributes.roseClassification" >Rose Classification: {{plant.attributes.roseClassification}}</li>
		<li *ngIf="plant.attributes.roseColor" >Rose Color: {{plant.attributes.roseColor}}</li>

		<!--
		<li *ngIf="plant.attributes.flowerTraits" >Flower Traits: {{plant.attributes.flowerTraits}}</li>
		<li *ngIf="plant.attributes.maxElevation" >Max Elevation: {{plant.attributes.maxElevation}}</li>
		<li *ngIf="plant.attributes.lightNeeds" >Light Needs: {{plant.attributes.lightNeeds}}</li>
		<li *ngIf="plant.attributes.usdaZone" >USDA Zones: {{plant.attributes.usdaZone}}</li>
		-->




		<li *ngIf="plant.attributes.vineType" >Vine Type: {{plant.attributes.vineType}}</li>
	</ul>
	
	<div class="addedPrice" *ngIf="addedPrice.enabled" cdkDrag>
		<div class="description">{{addedPrice.text}}</div>
		<div [style.font-size.rem]="fontSize" [style.color]="addedPrice.color" class="price">{{addedPrice.price}}<span [style.font-size.rem]="fontSizeSmall" class="cents">{{addedPrice.cents}}</span></div>
	</div>

	<p class="plant-description" contenteditable="true">
		{{plant.description}}
		<span class="tf-logo" contenteditable="false">
			<img src="../assets/images/tree-farm-logo.gif">
		</span>
	</p>
</div>

<div class="error" *ngIf="!plant && loaded">
	Plant not found or incorrect SKU - If you are sure the SKU is correct, try it without the '-', as there may be a bug.
</div>

	
<!--
 	<div id="no-print">
		
		<span *ngFor="let image of plant.attributes.imageUrl">
			<img id="main-image" src="http://www.thetreefarm.com/media/catalog/product{{image}}">
		</span>

		<p>{{plant.sku}}</p>
		<i class="wi wi-night-sleet"></i>
		<i class="wi wi-day-sunny"></i>
		<i class="wi wi-day-cloudy"></i>
		<i class="wi wi-hot"></i>
		<i class="wi wi-"></i>
	</div>
 -->
<!--
<input (blur)="getPlant($event)">


<ul>
	<li *ngFor="let plant of plants">
		<p>{{plant.name}}</p>
		<p>{{plant.sku}}</p>
		<p>{{plant.description}}</p>

	</li>
</ul>
		<img src="http://thetreefarm.com/media/catalog/product{{plant.attributes.imageUrl}}">
-->