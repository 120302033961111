<div class="flip-container">
	<input type="checkbox" name="" title="Click to Edit Values">
	<div class="flipper">
		<div class="front">
			<table>
				<thead>
					<tr>
						<th colspan="2">Quantity Pricing</th>
					</tr>
				</thead>
				
				<tbody>
					<tr>
						<td>{{tier.one.qty}}</td>
						<td class="price-display">{{tier.one.price}}</td>
					</tr>
					<tr>
						<td>{{tier.two.qty}}</td>
						<td class="price-display">{{tier.two.price}}</td>
					</tr>
				</tbody>
				<tfoot>
					<tr>
						<td colspan="2">Quantity pricing applies per SKU per Invoice.</td>
					</tr>
				</tfoot>
			</table>
			
		</div>
		<div class="back no-print">
			<input [(ngModel)]="tier.one.qty" placeholder="Tier One Quantity">
			<input [(ngModel)]="tier.one.price" placeholder="Tier One Price">
			<input [(ngModel)]="tier.two.qty" placeholder="Tier Two Quantity">
			<input [(ngModel)]="tier.two.price" placeholder="Tier Two Price">
		</div>
	</div>
</div>

