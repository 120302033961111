import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/Observable';
import { Http, Headers, HttpModule, Response, RequestOptions } from '@angular/http';
import { DefaultApi } from 'typescript-angular2-client';
import { THIS_EXPR } from '@angular/compiler/src/output/output_ast';

@Injectable()
export class TflookupService {

  options: RequestOptions;
  headers: Headers;
  response: Response;
  
  constructor(private api: DefaultApi, private http: Http) {}

  // importPlant(sku: string) {
  //   return this.api.plantMagentoGet(sku).subscribe(
	// 		response => this.response = response,
	// 		error => console.log('some error', error),
	// 		() => this.afterImport()
  //   );
  // }


  importPlant(sku: string, extraHttpRequestParams?: any): Observable<any> {
    return this.api.plantMagentoGet(sku, extraHttpRequestParams)
        .map((response: Response) => {
            if (response.status === 204) {
                return undefined;
            } else {
                return response.json();
            }
        });
  }

  importImage(sku: string, extraHttpRequestParams?: any): Observable<any> {
    return this.api.plantImage(sku, extraHttpRequestParams)
      .map((response: Response) => {
        if (response.status === 204) {
          return undefined;
        } else {
          return response.json();
        }
      })
  }



}
